import technician from "@/middleware/technician";


export default [
  {
    path: '/registration/:token',
    name: 'registration',
    component: () => import(/* webpackChunkName: "login" */ './views/full-page/Register'),
    meta: {
      rule: 'isPublic'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ './views/full-page/Login'),
    meta: {
      rule: 'isPublic'
    }
  },
  {
    path: '/password-recovery',
    name: 'password-recovery',
    component: () => import(/* webpackChunkName: "login" */ './views/full-page/PasswordRecovery'),
    meta: {
      rule: 'isPublic'
    }
  },
  {
    path: '/forgot_password',
    name: 'password-reset',
    component: () => import(/* webpackChunkName: "login" */ './views/full-page/PasswordReset'),
    meta: {
      rule: 'isPublic'
    }
  },

  {
    path: '/',
    name: 'home',
    redirect: '/map/projects',
    meta: {
      rule: 'isPublic'
    }

  },
  {
    path: '/map',
    name: 'map',
    component: () => import(/* webpackChunkName: "projects" */ './views/Map.vue'),
    children: [
      {
        path: '/map/projects',
        name: 'projects',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        components: {
          main_view: () => import(/* webpackChunkName: "projects" */ './components/project/ProjectTableComponent'),
        },
        meta: {
          rule: 'isUser'
        }
      },
      {
        path: '/map/packages',
        name: 'packages',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        components: {
          main_view: () => import(/* webpackChunkName: "projects" */ './components/package/PackageTableComponent'),
        },
        meta: {
          rule: 'isUser'
        }
      },
      {
        path: '/map/projects/:id',
        name: 'project.detail',
        components: {
          main_view: () => import(/* webpackChunkName: "projects" */ './views/ProjectDetail'),
          side_view: () => import(/* webpackChunkName: "projects" */ './views/ProjectDetailSide')
        },          meta: {
          rule: 'isUser'
        }
      },
      {
        path: '/meldkamer/projects/:id',
        name: 'meldkamer.project.detail',
        components: {
          main_view: () => import(/* webpackChunkName: "projects" */ './views/MeldkamerProjectDetail'),
          side_view: () => import(/* webpackChunkName: "projects" */ './views/MeldkamerProjectDetailSide')
        },          meta: {
          rule: 'isUser'
        }
      },
      // PACKAGE DETAIL
      {
        path: '/map/projects/:project_id/packages/:id',
        name: 'package.detail',
        components: {
          main_view: () => import(/* webpackChunkName: "projects" */ './views/PackageDetail'),
          side_view: () => import(/* webpackChunkName: "projects" */ './views/PackageDetailSide')
        },
        meta: {
          rule: 'isUser'
        }
      },

      // PACKAGE LOGS
      {
        path: '/map/projects/:project_id/packages/:id/logs',
        name: 'package.logs',
        components: {
          main_view: () => import(/* webpackChunkName: "projects" */ './views/PackageLogs'),
          side_view: () => import(/* webpackChunkName: "projects" */ './views/PackageDetailSide')
        },
        meta: {
          rule: 'isUser'
        }
      },
      // Project LOGS
      {
        path: '/map/projects/:id/logs',
        name: 'project.logs',
        components: {
          main_view: () => import(/* webpackChunkName: "projects" */ './views/ProjectLogs'),
          side_view: () => import(/* webpackChunkName: "projects" */ './views/ProjectDetailSide')
        },
        meta: {
          rule: 'isUser'
        }
      },

    ],
    meta: {
      rule: 'isUser'
    }
  },

  // Project Incidents
  {
    path: '/map/projects/:id/incidents/:i_id',
    name: 'project.incident',
    component: () => import(/* webpackChunkName: "projects" */ './views/ProjectIncident'),
    meta: {
      rule: 'isUser'
    }
  },

  // ALARMS
  {
    path: '/projects/:project_id/schemes',
    name: 'project.schemes',
    component: () => import(/* webpackChunkName: "schemes" */ './views/ProjectAlarmSchemes'),

    meta: {
      rule: 'isUser'
    }
  },

  // OUTPTUS
  {
    path: '/projects/:project_id/outputs',
    name: 'project.outputs',
    component: () => import(/* webpackChunkName: "schemes" */ './views/ProjectOutputs'),

    meta: {
      rule: 'isUser'
    }
  },

  // SENSORS
  {
    path: '/projects/:project_id/sensors',
    name: 'project.sensors',
    component: () => import(/* webpackChunkName: "schemes" */ './views/ProjectSensors'),

    meta: {
      rule: 'isUser'
    }
  },

  // SITES
  {
    path: '/admin/projects',
    name: 'admin.projects',
    component: () => import(/* webpackChunkName: "admin" */ './views/Projects'),
    meta: {
      middleware: technician
    }
  },
  // {
  //   path: '/projects/:id',
  //   name: 'project.detail',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "projects" */ './views/ProjectDetail')
  // },

  // MASTS
  {
    path: '/admin/packages',
    name: 'admin.packages',
    component: () => import(/* webpackChunkName: "admin" */ './views/Packages.vue'),
    meta: {
      middleware: technician
    }
  },
  // PACKAGES
  // {
  //   path: '/packages/:id',
  //   name: 'package.detail',
  //   component: () => import(/* webpackChunkName: "admin" */ './views/PackageDetail')
  // },
  {
    path: '/packages/:id/edit',
    name: 'package.edit',
    component: () => import(/* webpackChunkName: "admin" */ './views/PackageEdit'),
    meta: {
      middleware: technician
    }
  },
  {
    path: '/projects/:id/edit',
    name: 'project.edit',
    component: () => import(/* webpackChunkName: "admin" */ './views/ProjectEdit'),
    meta: {
    }
  },

  // ADMIN
  {
    path: '/admin/companies',
    name: 'companies',
    component: () => import(/* webpackChunkName: "admin" */ './views/companies/Companies'),
    meta: {
      rule: 'isTechnician'
    }
  },
  {
    path: '/admin/companies/:id',
    name: 'companies.detail',
    component: () => import(/* webpackChunkName: "admin" */ './views/companies/CompanyDetail'),
    meta: {
      rule: 'isTechnician'
    }
  },

  {
    path: '/admin/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "admin" */ './views/users/Users'),
    meta: {
      rule: 'isUser'
    }
  },
  {
    path: '/users/:id',
    name: 'users.detail',
    component: () => import(/* webpackChunkName: "admin" */ './views/users/UserDetail'),
    meta: {
      rule: 'isUser'
    }
  },
  {
    path: '/admin/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "admin" */ './views/Settings'),
    meta: {
      middleware: technician
    }
  },
  {
    path: '/admin/logs',
    name: 'logs',
    component: () => import(/* webpackChunkName: "admin" */ './views/Logs'),
    meta: {
      middleware: technician
    }
  },
  {
    path: '/admin/alerts',
    name: 'alerts',
    component: () => import(/* webpackChunkName: "admin" */ './views/Alerts'),
    meta: {
      middleware: technician
    }
  },

  {
    path: '/bigmap',
    name: 'bigmap',
    component: () => import(/* webpackChunkName: "projects" */ './views/full-page/FullscreenMap'),

    meta: {
      rule: 'isUser'
    }
  },

  // ADMIN


  // DEVICES
  {
    path: '/nas',
    name: 'Nas',
    component: () => import(/* webpackChunkName: "admin" */ './views/devices/Nas'),
    meta: {
      middleware: technician
    }
  },
  {
    path: '/cameras',
    name: 'Cameras',
    component: () => import(/* webpackChunkName: "admin" */ './views/devices/Cameras'),
    meta: {
      middleware: technician
    }
  },
  {
    path: '/alarms',
    name: 'Alarms',
    component: () => import(/* webpackChunkName: "admin" */ './views/devices/Alarms'),
    meta: {
      middleware: technician
    }
  },

  // VARIA
  {
    path: '/changelog',
    name: 'changelog',
    component: () => import('./views/Changelog'),
    meta: {
      rule: 'isPublic'
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy',
    component: () => import('./views/PrivacyPolicy'),
    meta: {
      rule: 'isPublic'
    }
  },
  {
    path: '/cookie-policy',
    name: 'cookie',
    component: () => import('./views/CookiePolicy'),
    meta: {
      rule: 'isPublic'
    }
  },
  {
    path: '/403',
    name: 'notallowed',
    component: () => import('./views/full-page/NotAllowed'),
    meta: {
      rule: 'isPublic'
    }
  },
  //
  // {
  //   path: '/wsplayground',
  //   name: 'wsplayground',
  //   component: () => import('./views/WebsocketPlayground'),
  //   meta: {
  //     rule: 'isPublic'
  //   }
  // },

]
