<template>
  <footer v-show="isFooterBarVisible" class="footer">
    <div class="container-fluid">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
              <p class="text is-size-7">&copy; TowerEye BVBA {{ year }}, All Rights Reserved </p>
          </div>
          <div class="level-item">
            <p class="text is-size-7"><a @click="$router.push({ name: 'privacy'})">Privacy Policy</a></p>
          </div>
          <div class="level-item">
            <p class="text is-size-7"><a @click="$router.push({ name: 'cookie'})">Cookie Policy</a></p>
          </div>
        </div>

        <div class="level-right">
            <div class="level-item">
              <p class="text is-size-7">Concept, Design & Development by TowerEye</p>
            </div>
            <div class="level-item">
              <cookie-law theme="dark-lime"
                          buttonClass="button is-success"
              >
                <div slot="message">
                  This website uses cookies to ensure you get the best experience on our website.
                  <router-link to="/cookie-policy" class="has-text-grey-light">Read more.</router-link>
                </div>
              </cookie-law>
            </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'FooterBar',
    components: { CookieLaw },
    computed: {
    year () {
      return moment().year()
    },
    ...mapState("settings", [
      'isFooterBarVisible'
    ])
  }
}
</script>
