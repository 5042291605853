<template>
  <div id="app">
    <nav-bar/>
    <aside-menu v-if="loggedin" :menu="menu" @menu-click="menuClick"/>
    <transition name="fade" mode="out-in">
      <router-view class="main-container"/>
    </transition>
    <aside-right/>
    <footer-bar/>
    <overlay/>

  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import Overlay from '@/components/Overlay'
import AsideRight from '@/components/AsideRight'
import FooterBar from '@/components/FooterBar'
import store from "@/store";
import {mapState} from "vuex";
import settings from "@/store/modules/settings.store"


export default {
  name: 'home',
  components: {
    AsideMenu,
    Overlay,
    NavBar,
    AsideRight,
    FooterBar
  },

  data() {
    return {}
  },
  created() {
    if(!settings.isRegistered) {
      this.$store.registerModule('settings', settings)
      settings.isRegistered = true
    }
  },
  computed: {
    loggedin() {
      return this.$store.state.auth.authenticated
    },
    ...mapState('settings', [
      'deploActive'
    ]),

    menu() {

      let base_menu = []

      if(this.deploActive) {
        base_menu = base_menu.concat([
          [
            {
              action: 'return-to-portal',
              label: 'Return to Portal',
              icon: 'arrow-left'
            },
          ],
          'DEPLO',
          [

            {
              to: '/deplo',
              icon: 'map-marker',
              label: 'Projects'
            },
            {
              to: '/deplo/categories',
              icon: 'shape',
              label: 'Categories'
            },
            {
              to: '/settings',
              icon: 'wrench',
              label: 'Settings'
            },
          ]
        ])

      }

      if (this.$gates.getPermissions().map(p => p.split('.')[1]).includes("view project") || this.$gates.isSuperUser()) {
        base_menu = base_menu.concat([
          'PROJECTS',
          [
            {
              to: '/map/projects',
              icon: 'map-marker',
              label: 'My Projects'
            },
            {
              to: '/map/packages',
              icon: 'widgets',
              label: 'My Towers & Sets'
            },
            {
              to: '/bigmap',
              icon: 'mapbox',
              label: 'Map View'
            },
          ]
        ])
      }

      // Check if user has anpr access
      if (this.$gates.getPermissions().map(p => p.split('.')[1]).includes("view anpr data") || this.$gates.isSuperUser()) {
        base_menu = base_menu.concat([
          'ANPR',
          [
            {
              to: '/anpr',
              icon: 'car',
              label: 'My ANPR Cameras'
            },
          ]
        ])
      }

      // Check if it can at least manage 1 project
      if (this.$gates.getPermissions().map(p => p.split('.')[1]).includes("manage project")) {
        base_menu = base_menu.concat([
          'PROJECT ADMIN',
          [
            {
              to: '/admin/users',
              icon: 'account-multiple',
              label: 'Users'
            },
          ]
        ])
      }

      if (this.$gates.hasPermission('manage all projects') || this.$gates.isSuperUser()) {
        base_menu = base_menu.concat([
          'ADMIN',
          [
            {
              to: '/admin/projects',
              icon: 'map-marker',
              label: 'Projects'
            },
            {
              to: '/admin/packages',
              icon: 'widgets',
              label: 'Towers & Sets'
            },
            {
              to: '/admin/logs',
              icon: 'history',
              label: 'All Logs'
            },
            {
              to: '/admin/alerts',
              icon: 'alert',
              label: 'All Alerts'
            },
            {
              to: '/admin/companies',
              icon: 'office-building',
              label: 'Companies'
            },
            {
              to: '/admin/users',
              icon: 'account-multiple',
              label: 'Users'
            },
            {
              to: '/admin/settings',
              icon: 'wrench',
              label: 'Settings'
            }
          ]
        ])

        base_menu = base_menu.concat([
          'DEVICES',
          [

            {
              to: '/nas',
              icon: 'nas',
              label: 'Nas'
            },
            {
              to: '/cameras',
              icon: 'cctv',
              label: 'Cameras'
            },
            {
              to: '/alarms',
              icon: 'alarm-light',
              label: 'Alarms'
            },

          ]
        ])


      }

      base_menu = base_menu.concat([
        'HELP',
        [
          {
            href: 'https://towereye.be/helpdesk',
            icon: 'face-agent',
            label: 'Servicedesk'
          },
          {
            to: '/changelog',
            icon: 'notebook-outline',
            label: 'Release Notes'
          }
        ]
      ])


      return base_menu
    },
    roles() {
      return store.state.auth.user.roles.map(p => p.role)
    },
    user() {
      return store.state.auth.user
    }
  },

  watch: {
    user(newuser, olduser) {
      //this.$acl.change(store.state.auth.user.roles.map(p => p.role))

    }
  },


  methods: {
    menuClick(item) {
      if (item.menuSecondary) {
        this.menuSecondary = item.menuSecondary
        this.menuSecondaryLabel = item.menuSecondaryLabel ? item.menuSecondaryLabel : null
        this.menuSecondaryIcon = item.menuSecondaryIcon ? item.menuSecondaryIcon : null

        this.$store.commit('asideActiveForcedKeyToggle', item)
        this.$store.commit('overlayToggle', true)
      } else if (item.action) {
      }
      if (item.action === 'dark-mode-toggle') {
        this.$store.commit('settings/darkModeToggle')
      }
      else if (item.action === 'return-to-portal') {
        this.$store.commit('settings/deploToggle', false)
        this.$router.push("/")
      }
      else if (item.action === 'goto-deplo') {
        this.$store.commit('settings/deploToggle', true)
        this.$router.push("/deplo")
      }
    },
  }
}
</script>

<style>
.main-container {
  min-height: calc(100vh - 5.5rem);
}
</style>
