import Vue from 'vue'
import Router from 'vue-router'
import auth from "@/store/modules/auth.store"
import store from "./store"

import mainRoutes from './main.routes'
import anprRoutes from './components/anprv2/anpr.routes';
import deploRoutes from './components/deplo/deplo.routes';

Vue.use(Router)

if(!auth.isRegistered) {
  store.registerModule('auth', auth)
  auth.isRegistered = true
}

const router = new Router({
  base: '/',
  mode: 'history',

  routes: [...mainRoutes, ...anprRoutes, ...deploRoutes],

  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }

})
// TODO Check if still necessary!

// Creates a `nextMiddleware()` function which not only
  // runs the default `next()` callback but also triggers
      // the subsequent Middleware function.
          function nextFactory(context, middleware, index) {
              const subsequentMiddleware = middleware[index];
              // If no subsequent Middleware exists,
                  // the default `next()` callback is returned.
                      if (!subsequentMiddleware) return context.next;

                  return (...parameters) => {
                  // Run the default Vue Router `next()` callback first.
                      context.next(...parameters);
                  // Then run the subsequent Middleware with a new
                      // `nextMiddleware()` callback.
                          const nextMiddleware = nextFactory(context, middleware, index + 1);
                  subsequentMiddleware({ ...context, next: nextMiddleware });
                };
            }

  router.beforeEach((to, from, next) => {
      if (to.meta.middleware) {
          const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
                : [to.meta.middleware];

              const context = {
                from,
                next,
                router,
                to,
              };
          const nextMiddleware = nextFactory(context, middleware, 1);

              return middleware[0]({ ...context, next: nextMiddleware });
        }

          return next();
    });



router.beforeEach((to, from, next) => {

  if (to.path !== '/login' && to.name !== 'registration' && to.name !== 'password-recovery' && to.name !== 'password-reset' && !store.state.auth.authenticated) {
    const loginpath = window.location.pathname;
    next({ replace: true, name: 'login', query: { redirect: loginpath } });
  } else  if (to.path === '/login' && store.state.auth.authenticated) {
    next('/')
  }
  else {
    next();
  }

})

export default router
