export default [

    {
        path: '/deplo',
        name: 'deplo.projects',
        component: () => import('./views/DeploProjects'),
    },
    {
        path: '/deplo/projects/:project_id',
        name: 'deplo',
        component: () => import('./views/DeploHome'),
        children: [
            {
                path: '/deplo/projects/:project_id',
                name: 'mapitems.overview',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                components: {
                    side_view: () => import(/* webpackChunkName: "deplo" */ './components/MapItemOverviewSidebar')
                },
            },
            {
                path: '/deplo/projects/:project_id/items/:id',
                name: 'mapitems.detail',
                components: {
                    side_view: () => import(/* webpackChunkName: "deplo" */ './components/MapItemDetailSidebar')
                },
            },
            {
                path: '/deplo/projects/:project_id/connections/:id',
                name: 'connections.detail',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                components: {
                    side_view: () => import(/* webpackChunkName: "projects" */ './components/ConnectionDetailSidebar')
                },
            }
        ]
    },
    {
        path: '/deplo/categories',
        name: 'deplo.categories',
        component: () => import('./views/Categories'),
    },
    {
        path: '/deplo/projects/:project_id/items',
        name: 'deplo.items',
        component: () => import('./components/ItemTableComponent'),
    },
    {
        path: '/deplo/map2',
        name: 'deplo.map',
        component: () => import('./views/map2'),
    }



];
